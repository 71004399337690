import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="CPA Services for Individuals | DC, MD & VA | DeBlanc"
        description="Get help preparing your taxes, working on your estate plan, or managing your wealth. DeBlanc + Murphy has you covered. Serving the greater Washington, DC, area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="relative pb-10 pt-18 md:pt-16 md:pb-16">
        <div className="container">
          <header className="max-w-[720px]">
            <h1>CPA Services for Individuals</h1>
            <p className="mb-0 md:text-xl">
              Whether you need help preparing your taxes, working on your estate
              plan, or managing your wealth, DeBlanc + Murphy has you covered.
              Serving the greater Washington, DC area.
            </p>
          </header>
        </div>
      </section>

      <section className="bg-secondary-100 pt-20 pb-18 md:pt-18">
        <div className="container">
          <div className="grid gap-y-14 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
            <div>
              <Link
                to="/personal-tax-preparation-planning-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.1 Personal Tax Prep _ Planning.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Personal Tax Prep & Planning
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Get help preparing your taxes and putting smart tax
                      strategies in place for your future.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/estate-trust-planning-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.2 Estate _ Trust Planning.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Estate & Trust Planning
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Provide security for your family and protect your property
                      with estate and trust planning.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/crypto-consulting-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.3 Crypto Consulting.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Crypto Consulting
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Learn how to incorporate cryptocurrency investments into
                      your big-picture finances.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/family-owned-business-cpa/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.4 Family Business.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Family Business
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Get help reaching financial goals while balancing the
                      complex dynamics of a family business.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/irs-representation-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.5 IRS Representation.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      IRS Representation
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      We’ll be your authorized representative with the IRS and
                      handle all communications.
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div>
              <Link
                to="/wealth-management-services/"
                className="block h-full no-underline"
              >
                <div className="group">
                  <div className="-mx-4 mb-4 overflow-hidden md:mx-0">
                    <StaticImage
                      src="../images/3.0 Personal/1.6 Wealth Management.jpg"
                      loading="lazy"
                      width={373}
                      className="w-full scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <h2 className="heading-five mb-3 text-gray-700">
                      Wealth Management
                    </h2>
                    <p className="mb-0 font-normal text-gray-500">
                      Take steps to safeguard your assets, ensure financial
                      stability and reach your goals.
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <CallToAction paddingTop={true} />
    </Layout>
  );
};

export default Page;
